declare global {
  interface Window {
    environment: IEnvironment;
  }
}

interface IEnvironment {
  name: "local" | "test" | "stage" | "production";
}

interface IConfig {
  COMPLIANCE_API_URL: string;
  NOT_IN_SUBSCRIPTION_URL: string;
}

const initConfig = (): IConfig => {
  const envName = window.environment?.name ?? "test"; // default provided for tests

  switch (envName) {
    case "local":
      return {
        COMPLIANCE_API_URL: "https://localhost:5003/v1", //"https://test-api.compliancedev.creditsafe.com/v1" https://localhost:5003/v1,
        NOT_IN_SUBSCRIPTION_URL:
          "https://test.uppdev.creditsafe.com/notInSubscription?code=3034",
      };
    case "test":
      return {
        COMPLIANCE_API_URL: "https://test-api.compliancedev.creditsafe.com/v1",
        NOT_IN_SUBSCRIPTION_URL:
          "https://test.uppdev.creditsafe.com/notInSubscription?code=3034",
      };
    case "stage":
      return {
        COMPLIANCE_API_URL: "https://api.compliancestage.creditsafe.com/v1",
        NOT_IN_SUBSCRIPTION_URL:
          "https://stage.uppdev.creditsafe.com/notInSubscription?code=3034",
      };
    case "production":
      return {
        COMPLIANCE_API_URL: "https://protect-api.creditsafe.com/v1",
        NOT_IN_SUBSCRIPTION_URL:
          "https://app.creditsafe.com/notInSubscription?code=3034",
      };
  }
};

const config = initConfig();

export default config;
